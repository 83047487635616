@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Adamina&display=swap");
/* * {
  font-family: "Open Sans", sans-serif ;
}*/

@font-face {
  font-family: "EngrvrsOldEng BT";
  src: url("../src/assets/fonts/engravers/engraversOldEnglishBT.ttf");
}
.ubuntu {
  font-family: "Ubuntu", sans-serif;
}

.gradient {
  background: linear-gradient(
    180deg,
    #100e86 37.46%,
    #00b4d7 117.32%
  ) !important;
  opacity: 0.95 !important;
}

.policy > h2 {
  font-weight: bold;
  font-size: 24px;
  padding: 20px 0 20px 0;
}

.policy > h1 {
  font-weight: bold;
  font-size: 28px;
  padding: 20px 0 20px 0;
}

.policy > h3 {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 0 20px 0;
}

.policy > b {
  font-weight: 600;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Open Sans", sans-serif;
}

@layer components {
  .mon-hover {
    @apply lg:hover:opacity-40 transition ease-in-out duration-200 cursor-pointer disabled:hover:opacity-100;
  }
  .tech-scroll {
    @apply scrollbar-thumb-tech-blue1 scrollbar-track-[#D9D9D9] rounded-md scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full;
  }
}
.underline_animation {
  @apply relative after:content-[''] after:h-[1px] after:absolute after:bottom-0 after:left-0 after:right-0 after:w-0 after:bg-[#cbcbcb] hover:after:w-[100%] after:duration-300 hover:text-[#a8a7a7] cursor-pointer;
}

button:hover {
  opacity: 0.4;
  transition: opacity ease-in-out 0.2s;
  cursor: pointer;
}

button:disabled {
  opacity: 0.4;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: transparent;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:nth-of-type(3) {
  font-weight: 700;
  font-size: 24px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #100e86;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 3% 10px;
  background: none;
  text-align: center;
  line-height: 16px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar-smallerer .react-calendar__tile {
  font-size: 11px;
  padding: 1% 8px;
}

.react-calendar-smallerer .react-calendar__navigation button:nth-of-type(3) {
  font-weight: 700;
  font-size: 18px;
}

.react-calendar-smaller .react-calendar__tile {
  font-size: 11px;
  padding: 3% 10px;
}

.react-calendar__tile abbr {
  display: block;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover abbr,
.react-calendar__tile:enabled:focus abbr {
  background-color: #e6e6e6;
}

.react-calendar__tile--now abbr {
  background: #100e86;
  border-radius: 4px;
  color: white;
}

.react-calendar__tile--now:enabled:hover abbr,
.react-calendar__tile--now:enabled:focus {
  background: rgb(16, 14, 134, 0.6);
}

.react-calendar__tile--hasActive abbr {
  background: #8785ff;
}

.react-calendar__tile--hasActive:enabled:hover abbr,
.react-calendar__tile--hasActive:enabled:focus abbr {
  background: #8785ff;
}

.react-calendar__tile--active abbr {
  background: #8785ff;
  color: white;
  border-radius: 4px;
}
.highlight abbr {
  background: rgb(255, 214, 137);
  color: white;
  border-radius: 4px;
}

.react-calendar__tile--active:enabled:hover abbr,
.react-calendar__tile--active:enabled:focus abbr {
  background: #8785ff;
}

.react-calendar--selectRange .react-calendar__tile--hover abbr {
  background-color: #e6e6e6;
  border-radius: 4px;
}

.trunc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.trunc-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

input[type="password"]::-ms-reveal {
  display: none;
}
.react-calendar__navigation__label {
  font-size: 10px !important;
}
.react-calendar__month-view__weekdays {
  font-size: 10px !important;
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.body__overflow {
  overflow: hidden;
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 10px; /* Specified height */
  background: #e0e7ff; /* Grey background */
  outline: none; /* Remove outline */
  border-radius: 6px;
}

/* Mouse-over effects */
/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 16px; /* Set a specific slider handle width */
  height: 16px; /* Slider handle height */
  background: #100e86; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #100e86;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
